// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-dashboard-js": () => import("./../../../src/pages/adminDashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-comments-js": () => import("./../../../src/pages/comments.js" /* webpackChunkName: "component---src-pages-comments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-settings-js": () => import("./../../../src/pages/paymentSettings.js" /* webpackChunkName: "component---src-pages-payment-settings-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-pw-forget-js": () => import("./../../../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

